h1, h2, h3, h4, h5, h6, p {
  letter-spacing: 1px;
  margin: 0;
  color: var(--text-colour);
}

h1 { font-size: clamp(1.3rem, 5rem + 1vw, 6.5rem) }
h2 { font-size: clamp(1.25rem, 2.5rem + .4vw, 3.05rem) }
h3 { font-size: clamp(1.2rem, 1rem + 1vw, 1.75rem) }
h4 { font-size: clamp(1rem, .7rem + .5vw, 2rem) }
h5 { font-size: clamp(.9rem, .5rem + .5vw, 2rem) }

p { 
  font-size: clamp(1rem, .5rem + .5vw, 2rem);
  line-height: 1.5em;
}

.text-spacing-min { letter-spacing: 2px }
.text-size-large { font-size: clamp(1.1rem, 1rem + 1vw, 2rem) }
.text-medium-bold { font-weight: 500 }
.text-bold { font-weight: 600 }
.text-coloured { color: var(--primary-colour) }