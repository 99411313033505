.lh-card {
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  background-color: var(--secondary-text-colour);
}

.profile-container {
  background-color: var(--secondary-text-colour);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.8fr .2fr;
}

.page-card {
  display: grid;
  grid-template-columns: 1.2fr .8fr;
}

/* RESUME */
.resume .page-card {
  grid-template-columns: .8fr 1.2fr;
  gap: 2em;
  padding: 4em 2em;
}

.resume-title {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.resume-description {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* PROJECTS */
.project-card {
  display: grid;
  grid-template-columns: 1.2fr .8fr;
}

.project-description {  
  padding: 4em 2em;
  ul {
    margin-top: 1em;
    list-style-type: none;
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
  }
  .project-link {
    color: var(--primary-colour);
    &:hover {
      font-weight: 500;
    }
  }
}

.project-title-container {
  width: 100%;
  margin: 0 0 2em -2em;
  border-left: 10px solid var(--primary-colour);
  h4 { margin-left: calc(2em - 20px) }
  h6 { margin-left: calc(2em - 10px) }
}

.project-image-container {
  cursor: pointer;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &:hover {
      transform: scale(1.05);
    }
  }
}

@media only screen and (max-width: 700px) {
  .page-card {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .project-card {
    flex-direction: column-reverse;
  }
}