.toggles-container {
  position: absolute;
  right: 2.5rem;
  top: 8rem;
  display: flex;
  gap: 3em;
  z-index: 1;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  gap: .4em;
}

.toggle-image-container {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: var(--overlay);
  display: grid;
  place-items: center;
  cursor: pointer;
  img {
    height: 2em;
    width: auto;
  }
}

.toggle-button-container {
  background-color: var(--toggle-background);
  box-shadow: var(--box-shadow);
  padding: 1em;
  position: absolute;
  top: 3em;
  right: 0;
  display: flex;
  gap: .5em;
  .toggle-button {
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    opacity: .5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .active-color-mode {
    border: 2px solid var(--primary-colour) !important;
    opacity: 1;
  }
}

/* Dark Theme Toggle */
.darkmode-toggle-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(3);
  cursor: pointer;
}

body {
  transition: transform 0.5s ease-in-out, var(--page-color-transition);
}

.darkmode_icon {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  transform-origin: center center;
  transition: transform 0.75s ease-in-out, var(--page-color-transition);
}

.darkmode_icon::after {
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  left: 4px;
  bottom: 1px;
  border-radius: 10px;
  box-shadow: -5px 1px 0 0 var(--overlay);
  transform-origin: center center;
  transition: transform 0.5s ease, left 0.25s ease, bottom 0.25s ease, var(--page-color-transition);
}

.darkmode_icon .ray {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 4px;
  height: 4px;
  border-radius: 6px;
  transform-origin: center;
  transition: transform 0.5s ease-in-out, var(--page-color-transition);
}

.ray:nth-child(1) {
  transform: rotate(45deg) translateX(0);
}
.ray:nth-child(2) {
  transform: rotate(90deg) translateX(0);
}
.ray:nth-child(3) {
  transform: rotate(135deg) translateX(0);
}
.ray:nth-child(4) {
  transform: rotate(180deg) translateX(0);
}
.ray:nth-child(5) {
  transform: rotate(225deg) translateX(0);
}
.ray:nth-child(6) {
  transform: rotate(270deg) translateX(0);
}
.ray:nth-child(7) {
  transform: rotate(315deg) translateX(0);
}
.ray:nth-child(8) {
  transform: rotate(360deg) translateX(0);
}

[data-theme="dark"] {
  & .darkmode_icon {
    transform: scale(0.6);
    background: var(--overlay);
  }
  & .darkmode_icon::after {
    left: 15px;
    bottom: 8px;
    transform: scale(0);
  }
  & .darkmode_icon .ray {
    background: var(--overlay);
  }
  & .ray:nth-child(1) {
    transform: rotate(45deg) translateX(-16px);
  }
  & .ray:nth-child(2) {
    transform: rotate(90deg) translateX(-16px);
  }
  & .ray:nth-child(3) {
    transform: rotate(135deg) translateX(-16px);
  }
  & .ray:nth-child(4) {
    transform: rotate(180deg) translateX(-16px);
  }
  & .ray:nth-child(5) {
    transform: rotate(225deg) translateX(-16px);
  }
  & .ray:nth-child(6) {
    transform: rotate(270deg) translateX(-16px);
  }
  & .ray:nth-child(7) {
    transform: rotate(315deg) translateX(-16px);
  }
  & .ray:nth-child(8) {
    transform: rotate(360deg) translateX(-16px);
  }
}