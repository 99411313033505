.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: var(--overlay);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 90%;
  height: 90%;
  background-color: var(--toggle-background);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 2em 4em;
  animation: reveal 500ms linear;
}

.modal-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 2em;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: .5;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  background-color: transparent;
  border: none;
  width: fit-content;
}

.close-button img {
  width: 1em;
  height: 1em;
}

[data-theme="light"] {
  & .close-button img {
    filter: brightness(0);
  }
}