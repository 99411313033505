.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem;
  background-color: var(--secondary-text-colour);
  z-index: 2;
  img {
    width: 30px;
    height: auto;
  }
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  gap: .8rem;
  flex-wrap: wrap;
}

.dec-square {
  height: 16px;
  width: 16px;
  background-color: var(--primary-colour);
}

.navbar-logo-container .heading-initials {
  display: none;
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  gap: 1rem;
}

.navbar-link:hover {
  color: var(--primary-colour);
}

.active-link {
  color: var(--primary-colour);
  font-weight: 500;
}

/* Mobile View */
.navbar-hidden-menu {
  display: none;
  position: relative;
}

.navbar-hidden-menu-items {
  list-style-type: none;
  position: absolute;
  top: 2em;
  right: 0;
  background-color: var(--secondary-text-colour);
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 200px;
  border: 1px solid var(--primary-colour);
  border-radius: 5px;
}

@media only screen and (max-width: 960px) {
  .navbar-hidden-menu {
    display: flex;
  }

  .navbar-menu,
  .navbar-logo-container p {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .navbar-logo-container .heading-full {
    display: none;
  }

  .navbar-logo-container .heading-initials {
    display: block;
  }
}

@media only screen and (max-width: 350px) {
  .navbar {
    padding: 2.5rem 1em;
  }
}