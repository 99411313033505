@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$myFont: 'Montserrat', sans-serif;
$myFontSize: 100%;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $myFont;
  font-size: $myFontSize;
}

html, body, #root {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}