a {
  cursor: pointer;
  text-decoration: none;
  color: var(--text-colour);
}

.btn-pill {
  text-align: center;
  border-radius: 999px;
  padding: .5rem 1.5rem;
  background-color: var(--secondary-text-colour);
  border: 1px solid var(--text-colour);
  font-weight: 600;
  font-size: .9rem;
  &:hover {
    background-color: var(--primary-colour);
    color: var(--secondary-text-colour);
    border: 1px solid var(--primary-colour);
  }
}

.btn-colour {
  background-color: var(--primary-colour);
  color: var(--secondary-text-colour);
  border: 1px solid var(--primary-colour);
  font-weight: 600;
  font-size: .9rem;
  &:hover {
    background-color: var(--secondary-text-colour);
    color: var(--text-colour);
  }
}