.lh-page-layout {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.home {
  background-image: linear-gradient(0deg, var(--secondary-colour) 65%, var(--background-main) 65%);
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  .home-container {
    background-color: transparent;
    width: 90%;
    display: grid;
    grid-template-columns: 40% calc(60% - 4em);
    grid-template-rows: 1fr;
    gap: 4em;
    padding: 2em;
  }
}

.profile-main {
  background-color: var(--background-primary);
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: center;
  img {
    width: 50%;
    height: auto;
  }
  .text-divider {
    width: 50px;
    height: 5px;
    background-color: var(--primary-colour);
  }
}

.description-container {
  margin: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  .decription-buttons {
    display: flex;
    gap: 1rem;
  }
}

.profile-footer {  
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.7rem;
  img {
    width: 1.8rem;
    filter: brightness(0);
  }
}

.lh-page {
  background-color: var(--secondary-colour);
  display: flex;
  flex-direction: column;
  padding: 4em 2em;
  justify-content: center;
  align-items: center;
  section {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: 2em;
  }
  section:not(:first-of-type) {
    margin-top: 4em;
  }
}

.lh-page-card-container {
  display: flex;
  flex-direction: column;
  gap: 4em;
}


@media only screen and (min-width: 900px) {
  .home {
    background-image: linear-gradient(90deg, var(--secondary-colour) 40%, var(--secondary-text-colour) 40%);  
    .home-container {    
      margin: 6em 0;
      width: clamp(200px, 50% + 50vw, 1000px);
    }
  }
}

@media only screen and (max-width: 900px) {
  .home .home-container {    
    grid-template-columns: 1fr;
    padding: 1em;
  }
}

@media only screen and (min-width: 900px) {
  .lh-page section {
    width: clamp(700px, 40% + 5vw, 1000px);
  }
}

/* PROJECTS */
@media only screen and (min-width: 900px) {
  .projects section {
    width: clamp(700px, 40% + 5vw, 1000px);
  }  
}

@media only screen and (max-width: 350px) {
  .lh-page {
    padding: 2.5em 1em;
    section {
      width: 100%;
    }
  }

  .home .home-container {    
    width: 100%;
  }

  .decription-buttons {
    flex-direction: column;
  }
}